<template>
    <v-container class="pa-0" fluid>
        <page-title
                :heading="$t('generic.lang_timecardDaily') "
                :icon="icon"
                :subheading="$t('generic.lang_timecardDaily')"
                show-previous-button
                url-previous="/timetrack/timeCardMonthly"
        ></page-title>
        <div class="app-main__inner">
            <track-component/>
        </div>
    </v-container>
</template>

<script>
    //components
    import PageTitle from "../../../Layout/Components/PageTitle.vue";
    import TrackComponent from "./../../../components/timesheet/monthlytimecard/TrackComponent";

    export default {
        components: {
            TrackComponent,
            PageTitle,

        },
        data: () => ({
            icon: "pe-7s-date icon-gradient bg-tempting-azure"
        })
    };
</script>

<style>
</style>