<template>
  <v-container class="pa-0" fluid>
    <v-card class="ma-0 pa-0" elevation="6" outlined>
      <v-row align="center" class="pl-3 pr-3" justify="center">
        <v-col :class="[this.$vuetify.theme.dark? '' : 'grey lighten-4']" class="text-center" cols="12">
          <v-avatar class="mx-auto" color="primary" size="128">
            <v-img
                :src="this.data.avatar || 'https://www.shareicon.net/data/128x128/2016/07/26/802001_man_512x512.png'"
            />
          </v-avatar>
        </v-col>

        <v-col :class="[this.$vuetify.theme.dark? '' : 'grey lighten-4']" class="text-center" cols="12">
          <h4>{{ this.data.editUserPreName + " " + this.data.editUserLastName }}</h4>
        </v-col>

        <v-col :class="[this.$vuetify.theme.dark? '' : 'grey lighten-4']" class="text-center mb-0 pb-0" cols="12">
          <birthdaypicker current dense :label="$t('generic.lang_month')" type="month"
                          v-bind:current-date="month"
                          v-model="month"/>
        </v-col>

        <v-col :class="[this.$vuetify.theme.dark? '' : 'grey lighten-4']" class="text-center  mt-0 pt-0 mb-3" cols="12">
          <v-btn :disabled="this.loading" :loading="this.loading" @click="update()" color="primary"
                 v-if="!this.closed">
            {{ $t('timetrack.lang_updateMonth') }}
          </v-btn>

          <v-btn @click="commit()" color="error" v-if="!this.closed">
            {{ $t('generic.lang_personalsettimes') }}
          </v-btn>

          <v-btn @click="newDay()" color="success" v-if="!this.closed">
            {{ $t('timetrack.lang_newShift') }}
          </v-btn>
        </v-col>


        <v-col class="text-center" cols="12">

          <v-data-table
              :headers="headers"
              :items="this.monthInfo || []"
              :items-per-page="15"
              class="pa-0 ma-0 elevation-0"
          >

            <template v-slot:item.displayDay="{ item }">
              <strong :class="item.closedAT > 0? 'error--text' : 'primary--text'">{{ item.displayDay }} </strong>
              <span :class="item.closedAT > 0? 'error--text' : 'primary--text'">{{ item.day }}</span>
            </template>

            <template v-slot:item.dayType="{ item }">
              <b-form-select :disabled="item.closedAT > 0" :options="tags" :readonly="item.closedAT > 0"
                             :state="item.state"
                             text-field="name" v-model="item.dayType" value-field="id"/>
            </template>

            <template v-slot:item.startTS="{ item }">
              <b-form-input :disabled="item.closedAT > 0" :formatter="LimitedFormatter"
                            :readonly="item.closedAT > 0"
                            :state="item.state" @keypress="isNumber($event , item.startTS)"
                            class="timeInput"
                            lazy-formatter
                            v-if="!item.newDay"
                            v-model="item.startTS" value-field="id"/>
              <div class="mt-6 ma-0" v-else>

                <v-datetime-picker
                    :disabled="item.closedAT > 0"
                    :readonly="item.closedAT > 0"
                    :textFieldProps="{outlined:true,dense:true, rules: [v => !!v]}"
                    :timePickerProps="{
                                                format: '24hr'
                                            }"
                    class="ma-0"
                    v-bind:datePickerProps="{
                                              max: item.MaxStartDate,
                                        }"
                    v-model="item.startTS"/>
              </div>
            </template>


            <template v-slot:item.endTS="{ item }">

              <div class="mt-6 ma-0">
                {{ item.endTs }}
                <v-datetime-picker
                    :disabled="item.closedAT > 0"
                    :readonly="item.closedAT > 0"
                    :textFieldProps="{outlined:true,dense:true, rules: [v => !!v]}"
                    :timePickerProps="{
                                     format: '24hr'
                                }"
                    class="ma-0"
                    v-bind:datePickerProps="{
                                              max:  item.MaxEndDate,
                                              min: item.MinEndDate
                                        }"
                    v-model="item.endTS"/>
              </div>
            </template>


            <template v-slot:item.overtime="{ item }">
              <b-form-input :disabled="item.closedAT > 0" :formatter="formatter"
                            :readonly="item.closedAT > 0"
                            @keypress="isNumber($event , item.overtime)"
                            class="timeInput" lazy-formatter
                            v-if="!item.newDay"
                            v-model="item.overtime"
                            value-field="id"/>

              <span v-else>{{ item.overtime }}</span>
            </template>

            <template v-slot:item.pauseTimeInSeconds="{ item }">
              <b-form-input :disabled="item.closedAT > 0" :formatter="formatter"
                            :readonly="item.closedAT > 0"
                            :state="item.state" @keypress="isNumber($event, item.pauseTimeInSeconds)"
                            class="timeInput"
                            lazy-formatter max="90:90"
                            v-model="item.pauseTimeInSeconds"
                            value-field="id"/>
            </template>

            <template v-slot:item.workTimeInSeconds="{ item }">
              <strong class="success--text">{{calcNetWorkingHours(item)}}</strong>

            </template>

            <template v-slot:item.action="{ item }">
              <v-btn :disabled="item.loading || loading" :loading="item.loading || loading"
                     @click="update(item)"
                     color="primary"
                     small
                     text v-if="item.closedAT == 0 && !item.newDay">
                <v-icon small>update</v-icon>
                {{ $t('generic.lang_save') }}
              </v-btn>

              <v-btn :disabled="item.loadingCommit || loadingCommitAll"
                     :loading="item.loadingCommit || loadingCommitAll"
                     @click="commit(item)"
                     color="error"
                     small
                     text v-if="item.closedAT == 0 && !item.newDay">
                <v-icon small>save</v-icon>
                {{ $t('generic.lang_commit') }}
              </v-btn>

              <v-btn :disabled="item.loading || loading" :loading="item.loading || loading"
                     @click="create(item)"
                     color="success"
                     small
                     text v-if="item.newDay">
                <v-icon small>add</v-icon>
                {{ $t('generic.lang_create') }}
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import Birthdaypicker from "../../common/birthdaypicker";
import TimePicker from "../../common/TimePicker";
import moment from "moment";

export default {
  name: "TrackComponent",
  components: {TimePicker, Birthdaypicker},
  watch: {
    month: {
      handler(val) {
        if (this.monthInfo !== null) {
          val && this.switchMonth(val)
        } else if (val)
          this.getMonth(val.split('-')[1], val.split('-')[0])
      },
      immediate: true,
      deep: true
    }
  },
  data() {
    return {
      month: "",
      tags: [
        {
          id: 1,
          name: this.$t('timetrack.workday')
        },
        {
          id: 2,
          name: this.$t('timetrack.holiday')
        },
        {
          id: 3,
          name: this.$t('timetrack.sickday')
        },
        {
          id: 4,
          name: this.$t('timetrack.feastday')
        },
        {
          id: 5,
          name: this.$t('timetrack.weekend')
        }
      ],
      form: {},
      data: {},
      selectedMonth: {},
      monthInfo: null,
      loading: false,
      loadingCommitAll: false,
      headers: [
        {
          text: this.$t('generic.lang_day'),
          value: 'displayDay'
        },
        {
          text: this.$t('timetrack.typeOfDay'),
          value: 'dayType',
          sortable: false
        },
        {
          text: this.$t('timetrack.lang_shiftStart'),
          value: 'startTS',
          sortable: false,
          align: "center"
        },
        {
          text: this.$t('generic.lang_cal_end_time'),
          value: 'endTS',
          sortable: false,
          align: "center"
        },
        {
          text: this.$t('timetrack.lang_overTime'),
          value: 'overtime',
          sortable: false,
          align: "center"
        },
        {
          text: this.$t('generic.lang_breakTime'),
          value: 'pauseTimeInSeconds',
          sortable: false,
          align: "center"
        },
        {
          text: this.$t('timetrack.lang_netWorkingTime'),
          value: 'workTimeInSeconds',
          sortable: false,
          align: "center"
        },
        {
          text: "",
          value: 'action',
          sortable: false
        }
      ]
    }
  },
  mounted() {
    this.getData();

    ///init current month
    let d = new Date();
    this.month = d.getFullYear() + '-' + (d.getMonth() + 1)
    //this.getMonth();
  },
  computed: {
    closed() {
      let self = this;

      if (self.monthInfo !== null) {
        return self.monthInfo.filter((elt) => elt.closedAT == 0).length == 0
      }
      return false;
    },

  },
  beforeDestroy() {

    if (this.monthInfo !== null) {
      let days = this.monthInfo.filter((elt) => elt.newDay == true);
      days.forEach((elt) => {
        let index = this.monthInfo.indexOf(elt)
        clearInterval(elt.interval)
        Object.assign(this.monthInfo[index], elt)
      })
    }

  },
  methods: {
    getM(item) {
      item.interval = setInterval(() => {
        let index = this.monthInfo.indexOf(item)
        item.day = item.startTS.getDate();
        item.MaxEndDate = this.getMaxDate(item)
        item.MinEndDate = this.getMinDate(item)
        Object.assign(this.monthInfo[index], item)
      }, 1000)
    },
    dateFormater(date) {
      let day = parseInt(date.getDate()) < 10 ? '0' + date.getDate() : date.getDate(),
          month = (parseInt(date.getMonth()) + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1),
          year = parseInt(date.getFullYear());
      return year + '-' + month + '-' + day
    },
    timeFormater(date) {
      let hours = parseInt(date.getHours()) < 10 ? '0' + date.getHours() : date.getHours(),
          minutes = parseInt(date.getMinutes()) < 10 ? '0' + date.getMinutes() : date.getMinutes();
      return hours + ':' + minutes
    },
    isNumber: function (evt, value) { // to accept only numbers
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 58) || (charCode === 58 && (value.match(/:/gi) || []).length >= 1)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    LimitedFormatter(value) {
      if (value.length > 0) {
        if (!value.includes(':')) {
          let h = value.substring(0, 2),
              m = value.substring(2, 5);

          if (h.length == 1)
            h = "0" + h;
          if (parseInt(h) > 23)
            h = "23";
          if (m.length == 1 && parseInt(m) < 10 && parseInt(m) >= 1) {
            m = "0" + m;
          } else if (m.length == 0) m = "00";

          if (parseInt(m) > 59)
            m = "59";

          return h + ":" + m
        } else {
          let time = value.split(':');
          if (time[0].length == 0)
            time[0] = "0" + time[0];
          if (parseInt(time[0]) > 23)
            time[0] = "23";

          if (time[1].length < 2 && parseInt(time[1]) < 10 && parseInt(time[1]) >= 1) {
            time[1] = "0" + time[1];
          }

          if (parseInt(time[1]) > 59)
            time[1] = "59";

          return time[0].substring(0, 2) + ":" + time[1].substring(0, 2)
        }
      } else if (value.length == 0) {
        return "00:00"
      }
    },
    getMaxDate(item) // this function to get max of ending time
    {
      let dt = new Date();
      dt.setFullYear(parseInt(this.month.split('-')[0]))
      dt.setMonth(this.month.split('-')[1]);
      dt.setDate((parseInt(item.day) + 1));

      let day = parseInt(dt.getDate()) < 10 ? '0' + dt.getDate() : dt.getDate()
      let month = parseInt(dt.getMonth()) < 10 ? '0' + dt.getMonth() : dt.getMonth()
      return dt.getFullYear() + "-" + month + "-" + day;

    },
    newDay() {
      let dt = new Date(this.month + '-' + '01');
      let maxDay = new Date(this.month + '-' + '02');
      let weekday = [this.$t('generic.Sonntag'), this.$t('generic.Montag'), this.$t('generic.Dienstag'), this.$t('generic.Mittwoch'), this.$t('generic.Donnerstag'), this.$t('generic.Freitag'), this.$t('generic.Samstag')];
      let day = {
        displayDay: weekday[dt.getDay()],
        loading: false,
        dayType: 1,
        day: 1,
        loadingCommit: false,
        pauseTimeInSeconds: "00:00",
        overtime: "00:00",
        startTS: dt,
        endTS: maxDay,
        workTimeInSeconds: 0,
        newDay: true,
      }

      day.MinEndDate = this.getMinDate(day);
      day.MaxEndDate = this.getMaxDate(day);
      day.MaxStartDate = this.dateFormater(new Date(parseInt(this.month.split('-')[0]), parseInt(this.month.split('-')[1]), 0))
      this.getM(day);

      this.monthInfo.unshift(day);
    },
    getMinDate(item) // this function to get min of ending time
    {
      let year = this.month.split('-')[0];
      let month = parseInt(this.month.split('-')[1]) < 10 ? '0' + this.month.split('-')[1] : this.month.split('-')[1];
      let day = parseInt(item.day) < 10 ? '0' + item.day : item.day;
      return year + '-' + month + '-' + day;
    },
    formatter(value) { //to format vaue of input to be time format
      if (value.length > 0) {
        if (!value.includes(':')) {
          let h = value.substring(0, 2),
              m = value.substring(2, 5);

          if (h.length == 1)
            h = "0" + h;

          if (m.length == 1 && parseInt(m) < 10 && parseInt(m) >= 1) {
            m = "0" + m;
          } else if (m.length == 0) m = "00";


          return h + ":" + m
        } else {
          let time = value.split(':');
          if (time[0].length == 0)
            time[0] = "0" + time[0];

          if (time[1].length < 2 && parseInt(time[1]) < 10 && parseInt(time[1]) >= 1) {
            time[1] = "0" + time[1];
          }


          return time[0].substring(0, 2) + ":" + time[1].substring(0, 2)
        }
      } else if (value.length == 0) {
        return "00:00"
      }
    },
    async switchMonth(val) {
      await this.getMonth(val.split('-')[1], val.split('-')[0])
    },
    timeToSeconds(time) {
      let arr = time.split(':');

      if (arr.length == 3)
        return (parseInt(arr[0]) * 3600) + (parseInt(arr[1]) * 60) + parseInt(arr[2]);
      else if (arr.length == 2)
        return (parseInt(arr[0]) * 3600) + (parseInt(arr[1]) * 60);
      else if (arr.length == 1)
        return (parseInt(arr[0]) * 3600);
    },
    calcNetWorkingHours(item){
      // (start - end time) + overtime - breaks
      if(!(item.StartDateTime && item.endTS))
        return 0;

      let diffStartEnd = moment(item.endTS.getTime()).diff(moment(item.StartDateTime.getTime()),'milliseconds')
      diffStartEnd =  diffStartEnd + moment.duration(item.overtime).asMilliseconds();
      let netWorkingDuration = moment.duration(diffStartEnd - moment.duration(item.pauseTimeInSeconds).asMilliseconds());
      if(netWorkingDuration.isValid()){
        return parseInt(netWorkingDuration.asHours())+' h:'+netWorkingDuration.get('minutes')+'m';
      }else{
        return 0;
      }
    },
    secondsToTime(secs) { //this function convert incoming times from DB as seconds to human time
      secs = Number(secs);
      var hours = Math.floor(secs / (60 * 60));

      var divisor_for_minutes = secs % (60 * 60);
      var minutes = Math.floor(divisor_for_minutes / 60);
      var divisor_for_seconds = divisor_for_minutes % 60;
      var seconds = Math.ceil(divisor_for_seconds);

      if (isNaN(secs)) return {
        "h": "00",
        "m": "00",
        "s": "00"
      }

      return {
        "h": hours < 10 ? '0' + hours : hours,
        "m": minutes < 10 ? '0' + minutes : minutes,
        "s": seconds < 10 ? '0' + seconds : seconds
      };
    },
    async prepareCommitData(item = null) {
      return new Promise((resolve, reject) => {
        let data = {}, index = -1;
        if (item === null) {
          data = {
            userID: this.$route.params.id,
            month: this.month.split('-')[1],
            year: this.month.split('-')[0]
          }
          this.loadingCommitAll = true;
          resolve({
            data, index
          })
        } else {
          index = this.monthInfo.indexOf(item)
          item.loadingCommit = true;
          Object.assign(this.monthInfo[index], item)
          data.shiftID = item.shiftID;

          resolve({
            data, index
          })
        }
      })
    },
    commit(item = null) {

      this.$swal({
        title: this.$t("generic.lang_personalsettimes") + " ?",
        text: this.$t("generic.lang_personalsettimes") + " ?",
        icon: "question",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t("generic.lang_apply"),
        preConfirm: () => {


          this.prepareCommitData(item).then(({data, index}) => {
            this.axios.post(ENDPOINTS.TIMESHEET.MONTHLYTIMECARD.COMMITSHIFT, data).then(async (res) => {
              if (res.data.opt === 'SUCCESS' || res.data.STATUS === 'SUCCESS') {
                Events.$emit("showSnackbar", {
                  message: this.$t('generic.lang_createdSuccessfully'),
                  color: "success"
                });
                await this.switchMonth(this.month);
              } else if (res.data.opt === 'FAILED') {
                Events.$emit("showSnackbar", {
                  message: res.data.msg,
                  color: "error"
                });
              } else {
                Events.$emit("showSnackbar", {
                  message: this.$t('generic.lang_errorOccurred'),
                  color: "error"
                });
              }
            }).catch((err) => {
              Events.$emit("showSnackbar", {
                message: err.message,
                color: "error"
              });
            }).finally(() => {
              if (item === null)
                this.loadingCommitAll = false;
              else {
                item.loadingCommit = false;

                Object.assign(this.monthInfo[index], item)
              }
            })
          })


        }
      });
    },
    async prepareShifts(item = null) {
      return new Promise((resolve, reject) => {
        let shifts = [], index = -1;
        if (item !== null) {
          let index = this.monthInfo.indexOf(item);
          item.loading = true;
          Object.assign(this.monthInfo[index], item)

          let tmp_moment = this.$moment(item.StartDateTime);

          tmp_moment.set({
            hour: item.startTS.split(':')[0],
            minute: item.startTS.split(':')[1]
          });
          shifts.push(
              {
                shiftID: item.shiftID,
                overtime: this.timeToSeconds(item.overtime),
                workshiftType: item.dayType,
                Start: tmp_moment.unix(),
                Ende: this.$moment(item.endTS).unix(),
                pause: "" + (parseInt(item.pauseTimeInSeconds.split(':')[0]) * 3600 + parseInt(item.pauseTimeInSeconds.split(':')[1]) * 60) + "",
                userID: this.$route.params.id
              }
          );

          resolve({
            shifts,
            index
          })
        } else {
          this.loading = true;
          this.monthInfo.forEach((item) => {
            if (item.closedAT == 0 && !item.newDay) {
              let tmp_moment = this.$moment(item.StartDateTime);

              tmp_moment.set({
                hour: item.startTS.split(':')[0],
                minute: item.startTS.split(':')[1]
              });


              shifts.push(
                  {
                    shiftID: item.shiftID,
                    overtime: this.timeToSeconds(item.overtime),
                    workshiftType: item.dayType,
                    Start: tmp_moment.unix(),
                    Ende: this.$moment(item.endTS).unix(),
                    pause: "" + (parseInt(item.pauseTimeInSeconds.split(':')[0]) * 3600 + parseInt(item.pauseTimeInSeconds.split(':')[1]) * 60) + "",
                  }
              );

            }

          })

          resolve({
            shifts,
            index
          })

        }
      });
    },
    update(item = null) {


      this.prepareShifts(item).then(({shifts, index}) => {

        this.axios.post(ENDPOINTS.TIMESHEET.MONTHLYTIMECARD.UPDATESHIFT, {
          shiftEntries: shifts
        }).then(async (res) => {
          if (res.data.opt === 'SUCCESS') {
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_createdSuccessfully'),
              color: "success"
            });
            await this.switchMonth(this.month)
          } else if (res.data.opt === 'FAILED') {
            Events.$emit("showSnackbar", {
              message: res.data.msg,
              color: "error"
            });
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_errorOccurred'),
              color: "error"
            });
          }
        }).catch((err) => {
          Events.$emit("showSnackbar", {
            message: err.message,
            color: "error"
          });
        }).finally(() => {
          if (item !== null) {
            item.loading = false;
            Object.assign(this.monthInfo[index], item);
          } else {
            this.loading = false;
          }
        })
      })

    },
    isShiftAlreadyInitialized(shift_id) {
      if (this.isIssetArr(this.monthInfo)) {
        let search = this.monthInfo.find(({shiftID}) => shiftID === shift_id);
        return this.isValidObject(search);
      }

      return false;
    },
    async getMonth(month = 0, year = 0) {
      let weekday = [this.$t('generic.Sonntag'), this.$t('generic.Montag'), this.$t('generic.Dienstag'), this.$t('generic.Mittwoch'), this.$t('generic.Donnerstag'), this.$t('generic.Freitag'), this.$t('generic.Samstag')];
      if (month == 0 && year == 0) {
        let d = new Date();
        month = (d.getMonth() + 1);
        year = d.getFullYear();

        this.month = d.getFullYear() + '-' + (d.getMonth() + 1)
      }
      this.monthInfo = [];

      await this
          .axios.post(ENDPOINTS.TIMESHEET.MONTHLYTIMECARD.MONTH, {
            userID: this.$route.params.id,
            year: year,
            month: month
          }).then((res) => {
            let days = null
                , date = new Date(year, month, 1);
            let moment_date = this.$moment(`${year}-${month}`, 'YYYY-MM');

            days = [...res.data.shiftData];


            while (Number(moment_date.format("MM")) === parseInt(month)) {

              let Item = days.filter(function (item) {
                return (Number(item.day) === Number(moment_date.format('DD')));
              });

              if (Item.length > 0) {
                Item.forEach((elt, i) => {
                  let index = days.indexOf(elt);
                  if (!this.isShiftAlreadyInitialized(days[index].shiftID)) {
                    days[index].displayDay = weekday[Number(moment_date.day())];
                    days[index].loading = false;
                    days[index].newDay = false;
                    days[index].loadingCommit = false;
                    days[index].pauseTimeInSeconds = this.secondsToTime(days[index].pauseTimeInSeconds).h + ":" + this.secondsToTime(days[index].pauseTimeInSeconds).m;
                    days[index].overtime = this.secondsToTime(days[index].overtime).h + ":" + this.secondsToTime(days[index].overtime).m;
                    let StartDateTime = days[index].startTS !== 0 ? new Date(days[index].startTS * 1000) : null;
                    if (StartDateTime) {
                      let time = StartDateTime.toTimeString().split(' GTM')[0].split(':');
                      days[index].startTS = time[0] + ':' + time[1];
                      days[index].StartDateTime = StartDateTime;
                    }
                    let EndDateTime = days[index].endTS != 0 ? new Date(days[index].endTS * 1000) : null;
                    if (EndDateTime) {
                      days[index].endTS = EndDateTime == 0 ? null : EndDateTime;
                    } else days[index].endTS = null;

                    days[index].MaxEndDate = this.getMaxDate(days[index]);
                    days[index].MinEndDate = this.getMinDate(days[index]);
                  }

                })
              }

              moment_date.add(1, 'days')
            }

            this.monthInfo = days.sort(function (a, b) {
              return parseInt(a.day) - parseInt(b.day);
            }) || null;

            this.$forceUpdate();
          }).catch((err) => {
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_errorOccurred'),
              color: "error"
            });
          }).finally(() => {

          });
    },
    getData() {
      this.axios.post(ENDPOINTS.SETTINGS.USER.GET, {
        userID: this.$route.params.id
      }).then((res) => {
        this.data = res.data.userData;
        /*let days = res[1].data.shiftData;
        if (days && days.length > 0)
            this.monthInfo = this.getDaysInMonth(d.getMonth() + 1, d.getFullYear(), days);*/
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => {

      });
    },
    create(item) {

      let index = this.monthInfo.indexOf(item);
      item.loading = true;
      Object.assign(this.monthInfo[index], item);

      ///

      let f = {};
      f.pauseTime = Math.round(+(parseInt(item.pauseTimeInSeconds.split(':')[0]) * 3600 + parseInt(item.pauseTimeInSeconds.split(':')[1]) * 60));
      f.userID = this.$route.params.id;
      f.entryType = item.dayType;
      f.startTimeStamp = this.$moment(item.startTS).unix();
      f.endTimeStamp = this.$moment(item.endTS).unix();
      this.axios.post(ENDPOINTS.TIMESHEET.DAILYTIMECARD.CREATE, f).then((res) => {
        if (res.data.STATUS === 'SUCCESS') {

          let days = this.monthInfo.filter((elt) => elt.newDay == true);
          days.forEach((elt) => {
            let index = this.monthInfo.indexOf(elt)
            clearInterval(elt.interval)
            Object.assign(this.monthInfo[index], elt)
          })

          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_createdSuccessfully'),
            color: "success"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.getMonth(this.month.split('-')[1], this.month.split('-')[0]);
      })

    },
    setMinMax(item) {
    }
  }
}
</script>

<style scoped>
.timeInput {
  width: 70px !important;
}
</style>
